<template>
  <div>
    <video-player v-if="videoOptions.sources[0].src" :options="videoOptions"></video-player>
  </div>
</template>

<script>
import VideoPlayer from "../components/VideoPlayer";

export default {
  name: "Rtmp",

  components: {VideoPlayer},

  data() {
    return {
      videoOptions: {
        autoplay: true,
        fluid: true,
        controls: true,
        liveui: true,
        responsive: true,
        sources: [
          {
            src: '',
            type: "application/x-mpegURL"
          }
        ],
      }
    }
  },

  mounted() {
    this.videoOptions.sources[0].src = 'https://meetcdn.hivelink.co/hls/' + this.$route.params.rtmp_link + '/index.m3u8'
    // this.videoOptions.sources[0].src = 'https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8'
  }
}
</script>

<style scoped>

</style>