<template>
  <div>
    <video ref="videoPlayer" crossOrigin="anonymous" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null,
    }
  },
  mounted() {
    videojs.Hls.GOAL_BUFFER_LENGTH = 5
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
<style>
video {
  border-radius: 20px !important;
}

.video-js {
  border-radius: 20px !important;
}

.vjs-control-bar {
  border-radius: 0 0 20px 20px !important;
}
</style>